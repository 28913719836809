import React, { Fragment, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Button,
    Modal
} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { MAUURL } from "../../../constant";
import "./UjianProcess.css";
const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
    </svg>
);

// const studentsData = [
//     {
//         id: 1,
//         kelas: "XII IPA 1",
//         rombel: "A",
//         nisn: "1234567890",
//         nama_siswa: "Ahmad Setiawan",
//         status: "proses",
//         responses: ["A", "B", "C", null, "E", "A", "D", null, "B", "C", "A", null, "D", "E", "B", null, "C", "A", "D", "B"],
//     },
//     {
//         id: 2,
//         kelas: "XII IPA 1",
//         rombel: "B",
//         nisn: "0987654321",
//         nama_siswa: "Siti Aminah",
//         status: "selesai",
//         responses: [null, "A", "B", "C", "D", null, "E", "A", "B", "C", null, "D", "E", "A", "B", null, "C", "D", "E", "A"],
//     },
//     {
//         id: 3,
//         kelas: "XII IPA 2",
//         rombel: "A",
//         nisn: "1122334455",
//         nama_siswa: "Rudi Santoso",
//         status: "proses",
//         responses: ["B", null, "A", "E", "D", "C", null, "B", "A", "D", "E", "C", "A", "B", null, "E", "C", "D", "B", "A"],
//     },
//     {
//         id: 4,
//         kelas: "XII IPA 2",
//         rombel: "B",
//         nisn: "3344556677",
//         nama_siswa: "Nina Lestari",
//         status: "selesai",
//         responses: ["C", "D", null, "A", "B", "E", "C", null, "A", "D", "B", "C", "A", null, "E", "D", "B", "C", "A", "E"],
//     },
//     {
//         id: 5,
//         kelas: "XII IPS 1",
//         rombel: "A",
//         nisn: "4455667788",
//         nama_siswa: "Budi Setiawan",
//         status: "proses",
//         responses: [null, "B", "C", "A", "E", "D", "B", "A", null, "C", "E", "D", "A", "B", "C", "D", "E", "A", null, "B"],
//     },
//     {
//         id: 6,
//         kelas: "XII IPS 1",
//         rombel: "B",
//         nisn: "5566778899",
//         nama_siswa: "Sari Dewi",
//         status: "selesai",
//         responses: ["A", "B", "C", null, "E", "D", "A", "B", "C", "D", null, "E", "C", "A", "B", "D", "E", "A", null, "B"],
//     },
//     {
//         id: 7,
//         kelas: "XII IPS 2",
//         rombel: "A",
//         nisn: "6677889900",
//         nama_siswa: "Rina Ayu",
//         status: "proses",
//         responses: [null, "C", "A", "D", "B", "E", "A", "B", "C", "D", null, "E", "B", "A", "C", "E", "D", "B", null, "A"],
//     },
//     {
//         id: 8,
//         kelas: "XII IPS 2",
//         rombel: "B",
//         nisn: "7788990011",
//         nama_siswa: "Arif Hidayat",
//         status: "selesai",
//         responses: ["E", "A", "B", null, "C", "D", "B", "A", "E", "C", "D", "B", null, "A", "C", "E", "D", "A", "B", null],
//     },
//     {
//         id: 9,
//         kelas: "XII IPA 3",
//         rombel: "A",
//         nisn: "8899001122",
//         nama_siswa: "Dewi Sartika",
//         status: "proses",
//         responses: ["A", null, "D", "B", "C", "E", "A", "B", null, "D", "E", "C", "A", "B", "E", null, "C", "D", "B", "A"],
//     },
//     {
//         id: 10,
//         kelas: "XII IPA 3",
//         rombel: "B",
//         nisn: "9900112233",
//         nama_siswa: "Bambang Susilo",
//         status: "selesai",
//         responses: [null, "C", "A", "B", "D", "E", "C", "A", null, "B", "D", "E", "A", "C", "B", "D", null, "E", "A", "B"],
//     },
//     {
//         id: 11,
//         kelas: "XII IPA 4",
//         rombel: "A",
//         nisn: "0011223344",
//         nama_siswa: "Sri Astuti",
//         status: "proses",
//         responses: ["E", "A", null, "C", "B", "D", "A", "E", "B", "C", null, "D", "A", "C", "B", "E", "D", null, "A", "B"],
//     },
//     {
//         id: 12,
//         kelas: "XII IPA 4",
//         rombel: "B",
//         nisn: "1122334456",
//         nama_siswa: "Rama Wijaya",
//         status: "selesai",
//         responses: ["C", "D", "A", "B", null, "E", "D", "C", "A", null, "B", "E", "C", "A", "D", "B", "E", null, "A", "C"],
//     },
//     {
//         id: 13,
//         kelas: "XII IPS 3",
//         rombel: "A",
//         nisn: "2233445567",
//         nama_siswa: "Ayu Lestari",
//         status: "proses",
//         responses: [null, "E", "A", "C", "B", "D", "C", null, "A", "B", "E", "D", "A", "B", null, "C", "E", "D", "B", "A"],
//     },
//     {
//         id: 14,
//         kelas: "XII IPS 3",
//         rombel: "B",
//         nisn: "3344556678",
//         nama_siswa: "Dedi Prasetyo",
//         status: "selesai",
//         responses: ["B", "C", "A", null, "D", "E", "B", "A", null, "C", "E", "D", "A", "B", "C", null, "E", "D", "A", "B"],
//     },
//     {
//         id: 15,
//         kelas: "XII IPS 4",
//         rombel: "A",
//         nisn: "4455667789",
//         nama_siswa: "Lina Mulyani",
//         status: "proses",
//         responses: ["A", null, "B", "C", "E", "D", "A", "B", "C", "D", "E", null, "B", "A", "C", "D", "E", null, "A", "B"],
//     },
//     {
//         id: 16,
//         kelas: "XII IPS 4",
//         rombel: "B",
//         nisn: "5566778890",
//         nama_siswa: "Dewi Anggraini",
//         status: "selesai",
//         responses: ["C", "D", "A", "B", null, "E", "D", "C", "A", "B", null, "E", "A", "C", "B", "D", "E", null, "A", "C"],
//     },
//     {
//         id: 17,
//         kelas: "XII IPA 5",
//         rombel: "A",
//         nisn: "6677889901",
//         nama_siswa: "Adi Wibowo",
//         status: "proses",
//         responses: [null, "A", "C", "B", "D", "E", "B", "A", null, "D", "C", "E", "A", "B", "C", "E", "D", null, "A", "B"],
//     }
// ];



// const numberOfQuestions = studentsData[0]?.responses.length || 0;
let search = window.location.search;
let params = new URLSearchParams(search);
let id = params.get('id');

const WorkingProcess = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/ujian/detail');
    };

    const [studentsData, setStudentsData] = useState(null);
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    useEffect(() => {

        if(id == "null" || !id){
            Swal.fire({
              title: "Invalid Akses",
              text: "Anda akan di arahkan ke MAU. Silahkan masuk kembali ke Test Online melalui Tombol 'Ujikan' yang ada di MAU.",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ya, Saya mengerti",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = MAUURL + '/daftar-ujian/test-online';
              }
            });
          }else{
            getUjianDetail();
          }
    }, [])

    const getUjianDetail = async () => {
      // /ujian/detail-jawaban/peserta/20
      var res = await axiosInstance.get(`ujian/${id}`);
      console.log(res);
      var data = res.data.data;
      var tmp = []
      for(var i = 0; i < data.peserta.length; i++){
        var response = []
        for(var a = 0; a < data.peserta[i].value.length; a++){
            response.push(data.peserta[i].value[a])
        }
        tmp.push(
            {
                id: data.peserta[i].id,
                kelas: data.peserta[i].detail.kelas,
                rombel: data.peserta[i].detail.rombel,
                nama_siswa: data.peserta[i].user.name,
                status: data.peserta[i].status,
                responses: response,
        })
      }

      setNumberOfQuestions(tmp[0]?.responses.length || 0)

      console.log(tmp)

      setStudentsData(tmp)
    }

    const [showKoreksiModal, setShowKoreksiModal] = useState(false);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const showJawaban = (response) => {
        setSelectedResponse(response);
        setShowKoreksiModal(true);
    }

    const handleCloseKoreksiModal = () => {
        setShowKoreksiModal(false);
        setSelectedResponse(null);
    }
    return (
        <Fragment>
            <PageTitle activeMenu="Table" motherMenu="Bootstrap" />
            <Row>

                <Col lg={12}>
                    <Card>
                        <Card.Header className="d-flex justify-content-between flex-wrap">
                            {/*<Card.Title>Recent Payments Queue</Card.Title>*/}
                            <div>
                                <Card.Title>Proses Pengerjaan</Card.Title>
                            </div>
                            {/* <div>
                                <span>Waktu Tersisa : 3 Menit</span>
                            </div> */}
                        </Card.Header>
                        {/* <Card.Header className="d-flex justify-content-between flex-wrap">
                            <Card.Title>Recent Payments Queue</Card.Title>
                            <div>

                            </div>
                            <div>
                                <Button className="me-2" variant="primary btn-sm" onClick={handleClick}>
                                    Mulai Ujian
                                    &nbsp;<i className="bi bi-skip-end-fill"></i>
                            </Button>

                            </div>
                        </Card.Header> */}
                        <Card.Body>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th className="width80">
                                        <strong>#</strong>
                                    </th>
                                    <th>Kelas</th>
                                    <th>Rombel</th>
                                    <th>Nama Siswa</th>
                                    {/* Dynamically create question columns */}
                                    {Array.from({length: numberOfQuestions}, (_, index) => (
                                        <th key={index}>{index + 1}</th>
                                    ))}
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {studentsData?.map((student, index) => (
                                    <tr key={index}>
                                        <td>
                                            <strong>{index}</strong>
                                        </td>
                                        <td>{student.kelas}</td>
                                        <td>{student.rombel}</td>
                                        <td>{student.nama_siswa}</td>
                                        {/* Render responses for each question */}
                                        {/* {JSON.stringify(student.responses[0])} */}
                                        {student.responses?.map((response, index) => (
                                            <td key={index}>
                                                {/* {typeof response.jawaban} */}
                                                {/* {response.jawaban == null ? 'null' : 'ada'} */}
                                                {response.jawaban != null ? (
                                                    <Badge style={{cursor: response.type == "UR" ? 'pointer' : 'auto'}}  onClick={() => response.type == "UR" ? showJawaban(response) : null} as="a" bg="badge-lg" bg={response.jawaban == "ragu" ? "warning" : response.status == "benar" ? "success" : "danger"}>
                                                        {response.type == "UR" ? <i className="fas fa-eye"></i> : response.jawaban}
                                                    </Badge>
                                                    ) : (
                                                        ""
                                                )}
                                            </td>
                                        ))}
                                        <td>
                                            {student.status == "finished" ? (
                                                <Badge bg="success">{student.status}</Badge>
                                            ) : (
                                                <Badge bg="danger">{student.status}</Badge>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal className="fade bd-example-modal-lg" size="md" show={showKoreksiModal}>
                <Modal.Header>
                    <Modal.Title>{'Jawaban'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={handleCloseKoreksiModal}></Button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={3}>Jawaban</Col>
                        {selectedResponse?.jawaban == null && <Col>: {"Tidak ada Jawaban"}</Col>}
                        {selectedResponse?.jawaban != null && <Col>: {selectedResponse?.jawaban.includes("|") && selectedResponse?.jawaban.split("|")[0] != "" && <img src={selectedResponse?.jawaban.split("|")[0]} alt="Captured" className="w-25 h-auto border rounded-md" />} <span dangerouslySetInnerHTML={{__html: selectedResponse?.jawaban.split("|")[1]}}></span></Col>}
                    </Row>
                </Modal.Body>
            </Modal>
        </Fragment>
    );

}

export default WorkingProcess;