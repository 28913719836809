import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {Row, Col, Card, Tab, Nav, ListGroup, Button} from "react-bootstrap";
import Highlight from "react-highlight";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import { withRouter } from "../../../utils";
import dayjs from "dayjs";
import 'dayjs/locale/id'
import Swal from "sweetalert2";
import { MAUURL } from "../../../constant";
dayjs.locale('id')
const Ujian = (props) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    const [listItem, setListItem] = useState([]);
    useEffect(() => {
        if(id == "null" || !id){
            Swal.fire({
              title: "Invalid Akses",
              text: "Anda akan di arahkan ke MAU. Silahkan masuk kembali ke Test Online melalui Tombol 'Ujikan' yang ada di MAU.",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ya, Saya mengerti",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = MAUURL + '/daftar-ujian/test-online';
              }
            });
            return;
          }else{
            getUjianData();
          }
        
    }, []);

    const getUjianData = async () => {
            

        var res = await axiosInstance.get(`ujian/${id}`);
        console.log(res);
        var ujianData = res.data.data;
        const listItem = [
            {
                title: 'Paket Soal Ujian',
                icon: "file-earmark-post",
                children: [
                    {
                        key: 'ID Ujian',
                        value: ujianData.code,
                    },
                    {
                        key: 'ID Paket Soal',
                        value: ujianData.paket_soal_code,
                    },
                    {
                        key: 'Nama Ujian',
                        value: ujianData.detail.nama,
                    },
                    {
                        key: 'Deskripsi Ujian',
                        value: ujianData.detail.deskripsi,
                    }
                ]
            },
            {
                title: 'Peserta Ujian',
                icon: "person",
                children: [
                    {
                        key: 'Tahun Ajaran',
                        value: ujianData.detail.tahun_ajaran,
                    },
                    // {
                    //     key: 'Semester',
                    //     value: 'Genap',
                    // },
                    // {
                    //     key: 'Jenjang',
                    //     value: 'SMK',
                    // },
                    // {
                    //     key: 'Nama Sekolah',
                    //     value: 'SMK Telkom Malang',
                    // },
                    {
                        key: 'Kelas',
                        value: ujianData.detail.kelas,
                    },
                    // {
                    //     key: 'Jurusan',
                    //     value: 'Rekayasa Perangkat Lunak',
                    // },
                    {
                        key: 'Rombel',
                        value: ujianData.detail.rombel,
                    },
                    // {
                    //     key: 'Kode Pelajaran',
                    //     value: 'RPL 2.1',
                    // }
                ]
            },
            {
                title: 'Pelaksanaan Ujian',
                icon: "list-task",
                children: [
                    {
                        key: 'Tanggal Ujian',
                        value: dayjs(ujianData.started_at).format('DD MMMM YYYY'),
                    },
                    {
                        key: 'Hari',
                        value: dayjs(ujianData.started_at).format('dddd'),
                    },
                    {
                        key: 'Waktu Ujian',
                        value: (ujianData.durasi/60) + ' Menit',
                    },
                    // {
                    //     key: 'Jam Ke-',
                    //     value: '1',
                    // },
                    // {
                    //     key: 'Nama Tempat Ujian',
                    //     value: 'SMK Telkom Malang',
                    // },
                    {
                        key: 'Ruang',
                        value: ujianData.detail.ruangan,
                    },
                    // {
                    //     key: 'Nama Gedung',
                    //     value: 'SMK Telkom Malang',
                    // },
                    // {
                    //     key: 'Alamat',
                    //     value: 'Jalan Raya Malang - Surabaya',
                    // }
                ]
            },
            // {
            //     title: 'Detail Paket Soal',
            //     icon: "newspaper",
            //     children: [
            //         {
            //             key: 'Pilihan Ganda',
            //             value: '10',
            //         },
            //         {
            //             key: 'Penjodohan',
            //             value: '10',
            //         },
            //         {
            //             key: 'Benar Salah',
            //             value: '10',
            //         },
            //         {
            //             key: 'Uraian',
            //             value: '10',
            //         },
            //         {
            //             key: 'Sebab Akibat',
            //             value: '10',
            //         }
            //     ]
            // }
          ];

        setListItem(listItem);
    }
    

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/ujian/monitoring?id='+id);
    };

    return (
        <Fragment>
            <PageTitle activeMenu="Ujian" motherMenu="Ujian" />
            <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
            <Row>
              <Col xl={12}>
              <Card name="default-tab" className="dz-card">
                    <Card.Header className="d-flex justify-content-between flex-wrap">
                        <div>
                            <Card.Title>Pengaturan Ujian</Card.Title>
                        </div>
                        <div>
                            <Button className="me-2" variant="primary btn-sm" onClick={handleClick}>
                                Monitoring
                                &nbsp;<i className="bi bi-skip-end-fill"></i>
                            </Button>
                        </div>
                    </Card.Header>
                    <Tab.Content>
                    {listItem.length > 0 && ( 
                    <Card.Body>
                          {/* <!-- Nav tabs --> */}
                          <div className="default-tab">
                            <Tab.Container defaultActiveKey={listItem[0].title.toLowerCase()}>
                              <Nav as="ul" className="nav-tabs">
                                {listItem.map((data, i) => (
                                  <Nav.Item as="li" key={i}>
                                    <Nav.Link eventKey={data.title.toLowerCase()}>
                                      <i className={`bi bi-${data.icon} me-2`} />
                                      {data.title}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                              <Tab.Content className="pt-4">
                                {listItem.map((data, iTab) => (
                                  <Tab.Pane eventKey={data.title.toLowerCase()} key={iTab}>
                                    {iTab != 3 ? (
                                        <ListGroup as="ul" variant="flush">
                                            <h4>{data.title}</h4>
                                        {data.children.map((list, i) => (
                                            <Fragment key={i}>
                                                <ListGroup.Item as="li">
                                                    <Row>
                                                        <Col sm={3}>{list.key}</Col>
                                                        <Col sm={1}>:</Col>
                                                        <Col sm={8}>{list.value}</Col>
                                                    </Row>
                                                </ListGroup.Item>      
                                            </Fragment>
                                        ))}
                                        </ListGroup>
                                    ) : (
                                        <div className="table-responsive">
                                            <div id="job_data" className="dataTables_wrapper">
                                                <table
                                                    className="display w-100 dataTable "
                                                    id="example5"
                                                    role="grid"
                                                    aria-describedby="example5_info">
                                                    <thead>
                                                    <tr role="row">
                                                    <th>
                                                        Bentuk Soal
                                                        </th>
                                                        <th>
                                                        Jumlah Soal
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                        {data.children.map((list, i) => (
                                                            <Fragment key={i}>
                                                                <tr>
                                                                <td>
                                                                {list.key}
                                                                </td>
                                                                <td>
                                                                {list.value}
                                                                </td>
                                                                
                                                                </tr>
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                  </Tab.Pane>
                                ))}
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </Card.Body>
                        )}
                    </Tab.Content>    
                  </Card>
              </Col>
            </Row>            
          </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('mapStateToProps header bursa', state);
    return {
        isAuthenticated: isAuthenticated(state),
        user: state.auth.auth,
    };
};
export default withRouter(connect(mapStateToProps)(Ujian));
