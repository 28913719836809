import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import axiosInstance from "../../../services/AxiosInstance";
import imageCompression from "browser-image-compression";
import { Button } from "react-bootstrap";

const CameraPopup = ({ isOpen, onClose, onCapture, onUploaded }) => {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState("user"); // "user" = depan, "environment" = belakang
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [isUploading, setIsUploading] = useState(false);
  const [cameraPermission, setCameraPermission] = useState(null); // "granted" | "denied" | "prompt"

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const permission = await navigator.permissions.query({ name: "camera" });
        setCameraPermission(permission.state);

        permission.onchange = () => {
          setCameraPermission(permission.state);
        };

        if (permission.state === "prompt") {
          await requestCameraPermission();
        }
      } catch (error) {
        console.error("Tidak bisa mengecek izin kamera:", error);
      }
    };

    checkCameraPermission();

    // Update tinggi layar saat orientasi berubah
    const updateHeight = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraPermission("granted");
      stream.getTracks().forEach(track => track.stop()); // Matikan stream setelah mendapatkan izin
    } catch (error) {
      setCameraPermission("denied");
      alert("Akses kamera ditolak! Izinkan akses kamera di pengaturan browser.");
    }
  };

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
    await uploadImage(imageSrc);
  };

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  };

  const uploadImage = async (imageSrc) => {
    try {
      setIsUploading(true);

      // Konversi data URL ke Blob
      const blob = await fetch(imageSrc).then((res) => res.blob());

      const options = {
        maxSizeMB: 1.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(blob, options);

      const formData = new FormData();
      formData.append("file", compressedFile, "capture.jpg");

      const response = await axiosInstance.post("media/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      onUploaded(response.data.url);
    } catch (error) {
      alert("Error: " + error.message);
    } finally {
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black">
      {cameraPermission === "denied" ? (
        <div className="text-white text-center">
          <p>🚫 Akses kamera tidak diizinkan!</p>
          <p>Silakan izinkan akses kamera di pengaturan browser.</p>
          <Button variant="primary" onClick={requestCameraPermission}>
            Coba Lagi
          </Button>
        </div>
      ) : (
        <>
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode }}
            className="w-100"
            style={{ height: `${screenHeight}px`, objectFit: "cover" }}
          />

          {/* Kontrol Kamera */}
          <div className="absolute bottom-5 d-flex justify-content-between w-100 px-5">
            <Button className="me-2 mt-2" variant="warning btn-md" onClick={toggleCamera}>
              Ganti Kamera
            </Button>

            <Button
              className="me-2 mt-2"
              variant="success btn-md"
              onClick={capture}
              disabled={isUploading}
            >
              {isUploading ? "Mengunggah..." : "Capture"}
            </Button>

            <Button className="me-2 mt-2" variant="danger btn-md" onClick={onClose}>
              Tutup
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CameraPopup;
